import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import {
  loginPasswordValidator,
  validateAge,
  loginUsernameValidator,
  passwordRules,
} from "../../Validator/register";
import { MultiSelect } from "react-multi-select-component";
import PasswordChecklist from "react-password-checklist";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { getEpins } from "../../utils/getEpinList";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config/config";
import { reverseNumberDisplay } from "../../utils/currencyNumberDisplay";
import Skeleton from "react-loading-skeleton";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import DatePickerComponent from "../Common/DatePickerComponent";
import dayjs from "dayjs";
import MyPayPalOrderButton from "../payment/PaypalOrderButton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Stripe from "../../views/payment/Stripe";
import { Alert } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
const RegisterForm = ({
  activeStep,
  setActiveStep,
  handleToggleRegisterModal,
  animateStepTransition,
  data,
  currency,
  placement,
  position,
}) => {
  const {
    register,
    setValue,
    setError,
    trigger,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const [epinValues, setEpinValues] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [activePaymentTab, setActivePaymentTab] = useState("");
  const [transPass, setTransPass] = useState("");
  const [transPassCheck, setTransPassCheck] = useState(false);
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const [getEwallet, setGetEwallet] = useState(false);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [transPassResposne, setTransPassResposne] = useState({
    success: null,
    error: null,
  });
  const [fileResponse, setFileResponse] = useState({
    success: null,
    error: null,
  });
  const [file, setFile] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedGender, setSelectedGender] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDob, setSelectedDob] = useState(null);
  const [contactField, setcontactField] = useState([])
  const [guardianRelation, setguardianRelation] = useState(null)
  const [occupation, setoccupation] = useState(null)
  const [contactCustomField, setcontactCustomField] = useState([])
  const dateOfBirthField = data?.contactInformation?.contactField?.find(
    (item) => item.code === "date_of_birth"
  );

  const ageLimit = dateOfBirthField?.options[0]?.validation.ageLimit;

  const [isMinAge, setIsMinAge] = useState({
    status: false,
    ageLimit: ageLimit,
  });
  const [isMaxAge, setIsMaxAge] = useState({
    status: false,
    ageLimit: 120,
  });
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [stripePublicKey, setStripePublicKey] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [stripeIntentStatus, setStripeIntentStatus] = useState('');
  const payTabPaymentPage = useRef(null)
  let regFromTree = 1;
  // ---------------------- watch the values of the Register Form --------------------------
  const formValues = watch();
  const moduleStatus = useSelector(
    (state) => state?.dashboard?.appLayout?.moduleStatus
  );
  const regAmount = useSelector(
    (state) => state?.dashboard?.appLayout?.configuration?.regAmount
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const sponsorImage = useSelector(
    (state) => state.dashboard?.appLayout?.user?.image
  );
  const { t } = useTranslation();
  const steps = data?.registrationSteps;

  // -------------------------------------- API ------------------------------------------------
  const userBalance = ApiHook.CallEwalletBalance(getEwallet, setGetEwallet);
  const registerMutation = ApiHook.CallRegisterUser(paymentIntentId);
  const stripeIntent = ApiHook.CallCreateStripePaymentIntent();
  const updateStripeIntent = ApiHook.CallUpdateStripePaymentIntent(paymentIntentId);
  const cancelStripeIntent = ApiHook.CallCancelStripePaymentIntent();
  const createPayTabpaymentPage = ApiHook.CallCreatePaytabPaymentPage()
  const countries = ApiHook.CallGetCountries();
  const countryStates = ApiHook.CallGetStates();
  const verifyCaptcha = ApiHook.CallverifyCaptcha()
  const [captchaVerified, setcaptchaVerified] = useState(false)
  const [captchaErr, setcaptchaErr] = useState('')
  const [captcha, setcaptcha] = useState(null)
  const Upload = ApiHook.CallBankUpload(
    "register",
    formValues?.username,
    setSubmitButtonActive,
    setValue,
    setFileResponse
  );
  const deleteBankReciept = ApiHook.CallDeleteBankReceipt(
    setSubmitButtonActive,
    setValue,
    setFileResponse,
    setFile
  );
  //----------------------Api call for field value check-----------------------------------
  const checkUsernameField = ApiHook.CallRegisterFieldsCheck();
  const checkEmailField = ApiHook.CallRegisterFieldsCheck();
  const transPassCheckData = ApiHook.CallTransPasswordCheck(
    transPass,
    transPassCheck,
    setTransPassCheck,
    setSubmitButtonActive,
    formValues.totalAmount,
    transPassResposne,
    setTransPassResposne,
    "e-wallet"
  );
  const epinList = getEpins(data?.validEpins, conversionFactor, currency);
  //-------------------------- Form Navigation -------------------------
  const genderOptions = (data) => {
    return data.map((item) => ({
      value: item.value,
      label: t(item.title),
    }));
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const countryList = (data) => {
    return data.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  };

  const stateList = (data) => {
    if (!formValues?.country) {
      return []; // Return an empty array if there's no country specified.
    }

    const selectedCountry = data.find(
      (country) => country.id === Number(formValues.country)
    );

    if (!selectedCountry) {
      return []; // Return an empty array if the selected country is not found.
    }

    return selectedCountry.States.map((state) => ({
      value: state.id,
      label: state.name,
    }));
  };
  console.log("=========formValues=======", formValues)

  const genderChange = (gender) => {
    setSelectedGender(gender);
    setValue("gender", gender?.value);
  };

  const changeCountry = (country) => {
    setSelectedCountry(country?.value);
    setSelectedState(null); // Clear the selected state
    setValue("state", null);
    setValue("country", country?.value); // Update the form value for country
  };

  const changeState = (state) => {
    setSelectedState(state?.value);
    setValue("state", state?.value);
  };

  const handleNext = async () => {
    let isValid
    if (
      activeStep === 2 &&
      !checkUsernameField.data?.data?.status &&
      checkUsernameField.data?.data?.field === "username" &&
      checkUsernameField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (
      activeStep === 1 &&
      !checkEmailField.data?.data?.status &&
      checkEmailField.data?.data?.field === "email" &&
      checkEmailField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (activeStep === 1 &&
      !checkEmailField.data?.data?.status &&
      checkEmailField.data?.data?.field === "email" &&
      checkEmailField.data?.data?.code === 1141) {
      setError('email', { message: t('mail_not_verified') })
    } else if (activeStep === 0 && data?.regData) {
      setValue("pv", data?.regData);
      isValid = true;
    } else {
      isValid = await trigger();
    }

    if (!formValues?.mobile && activeStep === 1) {
      isValid = false;
      setError("mobile", { message: t("this_field_is_required") });
    }


    if ((formValues?.password)?.includes(" ") && activeStep === 3) {
      isValid = false;
      setError("password", { message: ("password_cannot_contain_white_spaces") });
    }
    if (!isPhoneNumberValid && activeStep === 1) {
      isValid = false;
      setError("mobile", {
        message: t("min_length"),
      });
    }
    if (dateOfBirthField && !formValues?.date_of_birth && activeStep === 1) {
      isValid = setError("date_of_birth", {
        message: t("this_field_is_required"),
      });
    }
    if (!isMinAge?.status && isMinAge?.ageLimit && activeStep === 1) {
      // isValid = setError("date_of_birth", {
      //   message: t("ageValidation", { age: isMinAge?.ageLimit }),
      // });
    } else if (isMaxAge?.status && isMaxAge?.ageLimit && activeStep === 1) {
      isValid = setError("date_of_birth", {
        message: t("maxAgeLimit", { age: isMaxAge?.ageLimit }),
      })
    }
    //name validation
    if (activeStep === 1 && formValues.first_name?.length > 15) {
      isValid = false;
      setError("first_name", {
        message: t("max_length_is"),
      });
    }

    if (activeStep === 1 && formValues.last_name?.length > 15) {
      isValid = false;
      setError("last_name", {
        message: t("max_length_is"),
      });
    }

    // reg without package and regfee (product status on)
    if (data.regData === 0 && data.regPack.length === 0) {
      setSubmitButtonActive(false);
      const paymentType = data?.paymentGateways.find(item => item.title === "free-joining");
      setValue("paymentType", String(paymentType.id))
    }

    // auto select if there is only single payment method
    if (data?.paymentGateways.length === 1) {
      const defaultPayment = data?.paymentGateways[0];
      setActiveTab(defaultPayment.id);
      if (defaultPayment.id === 3) {
        setSubmitButtonActive(false);
      } else if (defaultPayment.id === 2) {
        setGetEwallet(true);
        setSubmitButtonActive(true);
        setValue("transactionPassword", transPass?.transPassword);
      } else {
        setSubmitButtonActive(true);
      }
      setValue("paymentType", String(defaultPayment.id));
    }

    // below is international mobile validation : uncomment if needed
    // if (!isPhoneNumberValid && activeStep === 2) {
    //   isValid = false;
    //   setError("mobile",{message:t("invalidPhone")});
    // }
    // auto select if there is only single payment method
    if (data?.paymentGateways.length === 1) {
      const defaultPayment = data?.paymentGateways[0];
      setActiveTab(defaultPayment.id);
      if (defaultPayment.id === 3) {
        setSubmitButtonActive(false);
      } else if (defaultPayment.id === 2) {
        setGetEwallet(true);
        setSubmitButtonActive(true);
        setValue("transactionPassword", transPass?.transPassword);
      } else {
        setSubmitButtonActive(true);
      }
      setValue("paymentType", String(defaultPayment.id));
    }

    if (isValid) {
      const nextStep = activeStep + 1;
      animateStepTransition(nextStep);
      setActiveStep(nextStep);
    }
  };
  console.log("==============error====", errors)
  const handleBack = () => {
    if (paymentIntentId !== '') {
      const body = {
        stripeStatus: stripeIntentStatus,
        paymentIntentId: paymentIntentId,
      }
      cancelStripeIntent.mutateAsync(body, {
        onSuccess: (res) => {
          if (res.status === "canceled") {
            // clear stripe
            setPaymentIntentId('');
            setClientSecret('');
            setStripeIntentStatus('');
          }
        }
      })
    }
    setActiveTab('');
    const prevStep = activeStep - 1;
    // below step is to clear the uploaded image & value
    if (activeStep === 4 || activeStep === 5) {
      setValue("bankReceipt", undefined);
    }
    if (file) {
      setFile(null);
    }
    animateStepTransition(prevStep);
    setActiveStep(prevStep);
    setSubmitButtonActive(true);
  };

  const handleSubmit = async (paymentId = null, paymentIntent, paymentType) => {
    if (paymentIntentId !== '' && paymentIntent === undefined) {
      const body = {
        stripeStatus: stripeIntentStatus,
        paymentIntentId: paymentIntentId,
      }
      cancelStripeIntent.mutateAsync(body, {
        onSuccess: (res) => {
          if (res.status === "canceled") {
            // clear stripe
            setPaymentIntentId('');
            setClientSecret('');
            setStripeIntentStatus('');
          }
        }
      })
    }
    if (paymentId === 6) {
      setValue("paymentType", paymentId.toString());
      formValues.paymentType = paymentId.toString();
    }
    if (paymentType === "stripe") {
      formValues.stripeResponse = paymentIntent;
    }
    else if (activePaymentTab === "e-pin") {
      if (!captchaVerified) return setcaptchaErr(t('captcha_not_verified'))
    }
    setSubmitButtonActive(true);
    const isValid = await trigger();
    if (transPass) {
      formValues.transactionPassword = transPass;
    }
    if (isValid) {
      if (placement) {
        setValue("placement", placement);
        formValues.placement = placement;
      } else {
        setValue("placement", data?.sponsorData?.username);
        formValues.placement = data?.sponsorData?.username;
      }
      const payload = removeCustomFields(formValues, data?.contactInformation?.customField);
      if (position) {
        payload.regFromTree = regFromTree;
        registerMutation.mutate(payload, {
          onSuccess: (res) => {
            if (!res.status && activePaymentTab === "stripe") {
              // clear stripe and refresh payment tab
              setActivePaymentTab("");
              setPaymentIntentId("");
              setClientSecret("");
              setStripeIntentStatus("");
              setActiveTab("");
            }
          }
        });
      } else {
        payload.regFromTree = 0;
        registerMutation.mutate(payload, {
          onSuccess: (res) => {
            if (!res.status && activePaymentTab === "stripe") {
              // clear stripe and refresh payment tab
              setActivePaymentTab("");
              setPaymentIntentId("");
              setClientSecret("");
              setStripeIntentStatus("");
              setActiveTab("");
            }
          }
        });
      }
    }
  };

  const handleCustomField = (id, value) => {
    let custom = { ...formValues.customFields };
    custom[id] = value;
    setValue("customFields", custom);
  };

  const handleProductClick = (productId, productName, pv, price) => {
    const totalAmount = Number(price) + JSON.parse(regAmount);
    setValue("totalAmt", `${CurrencyConverter(totalAmount, conversionFactor)}`);
    setValue(
      "product",
      { id: productId, name: productName, price: price },
      { shouldValidate: true }
    );
    setValue("pv", pv);
    setValue("totalAmount", totalAmount.toFixed(2));
    setValue("regAmount", regAmount);
  };

  const handlePaymentTabClick = async (tabId, title) => {
    setActivePaymentTab(title);
    if (formValues?.bankReceipt !== undefined) {
      setValue("bankReceipt", undefined);
    }
    setActiveTab(tabId);
    if (tabId === 3) {
      setSubmitButtonActive(false);
    } else if (tabId === 2) {
      setGetEwallet(true);
      setSubmitButtonActive(true);
      setValue("transactionPassword", transPass?.transPassword);
    } else if (title === "stripe") {
      setSubmitButtonActive(true);
      setStripePublicKey(data.paymentGateways.find((item) => item.title === "stripe").publicKey);
      if (paymentIntentId === '') {
        const body = {
          productId: formValues.product.id,
          type: "register"
        };
        stripeIntent.mutateAsync(body, {
          onSuccess: (res) => {
            if (res.status) {
              setClientSecret(res.client_secret);
              setPaymentIntentId(res.id);
              setStripeIntentStatus(res.status)
            } else {
              toast.error(res.description)
            }
          }
        });
      } else {
        const body = {
          productId: formValues.product.id,
          paymentIntentId: paymentIntentId,
          type: "register"
        };
        updateStripeIntent.mutateAsync(body, {
          onSuccess: (res) => {
            setStripeIntentStatus(res.status)
          }
        })
      }
    }
    else if (title === "pay-tabs") {
      setSubmitButtonActive(false);
      // const response = await createPayTabpaymentPage.mutateAsync(formValues)
      // console.log("=====paytabRes", response)
      // if (response.status) {
      //   if (response?.data?.redirect_url) {
      //     window.location.href = response?.data?.redirect_url

      //   }
      // } else {

      // }
    }
    else {
      setSubmitButtonActive(true);
    }
    setValue("paymentType", tabId.toString()); // Set the selected payment
    if (!moduleStatus.product_status) {
      setValue("totalAmount", regAmount.toFixed(2)); // regAmount is added to total amount. If there is no product
    }
  };

  const handleEpinChange = (epinValues) => {
    let newValues = [];
    let totalEpinAmount = 0;
    setEpinValues(epinValues);
    Object.entries(epinValues)?.map(([key, value]) => {
      totalEpinAmount =
        totalEpinAmount + reverseNumberDisplay(String(value.amount));
      newValues.push(value.value);
    });
    const balance =
      Number(reverseNumberDisplay(String(formValues?.totalAmount))) -
      Number(totalEpinAmount);

    if (balance <= 0) {
      setValue("epinBalance", 0);
      setSubmitButtonActive(false);
      toast.success(t("total_amount_achieved"));
    } else {
      setSubmitButtonActive(true);
      setValue("epinBalance", reverseNumberDisplay(String(balance)));
    }
    setValue("epins", newValues);
    setValue("totalEpinAmount", reverseNumberDisplay(String(totalEpinAmount)));
  };

  const removeItemByIndex = (index) => {
    let newBalance = 0;
    const remainingValues = [];
    const updatedEpinValues = [...epinValues];
    const removedItem = updatedEpinValues.splice(index, 1)[0]; // Remove and get the removed item
    setEpinValues(updatedEpinValues);
    // update epinValues
    updatedEpinValues.forEach((item) => {
      remainingValues.push(item.value);
    });
    // Recalculate totalEpinAmount and balance
    const newTotalEpinAmount =
      Number(reverseNumberDisplay(String(formValues.totalEpinAmount))) -
      Number(reverseNumberDisplay(String(removedItem.amount.toFixed(2))));

    if (newTotalEpinAmount < formValues.totalAmount) {
      newBalance =
        Number(reverseNumberDisplay(String(formValues?.totalAmount))) -
        newTotalEpinAmount;
    }
    // Update the state values
    setValue(
      "totalEpinAmount",
      reverseNumberDisplay(String(newTotalEpinAmount))
    );
    setValue("epinBalance", reverseNumberDisplay(String(newBalance)) ?? 0);
    setValue("epins", remainingValues);
    if (newBalance <= 0) {
      setSubmitButtonActive(false);
    } else {
      setSubmitButtonActive(true);
    }
  };

  const handleFileChange = (event) => {
    setFileResponse({
      success: null,
      error: null,
    });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    const type = "register";
    if (file) {
      Upload.mutate(file, type);
    }
  };

  const handleDeleteBankReciept = () => {
    const data = {
      filepath: formValues?.bankReceipt,
      type: "register",
    };
    if (formValues?.bankReceipt) {
      deleteBankReciept.mutateAsync(data);
    }
  };

  const openCalender = () => {
    setIsCalenderOpen(true);
  };

  const closeCalender = () => {
    setIsCalenderOpen(false);
  };

  const handleDateChange = (newDate, item) => {
    if (newDate) {
      const minAgeStatus = minAgeValidation(
        newDate,
        item.options[0]?.validation?.ageLimit
      )
      setIsMinAge({
        status: minAgeStatus,
        ageLimit: item.options[0]?.validation?.ageLimit,
      });
      setIsMaxAge({
        status: maxAgeValidation(newDate, 120),
        ageLimit: 120
      });
      setSelectedDob(newDate);
      const formattedDate = newDate.format("YYYY-MM-DD");
      setValue("date_of_birth", formattedDate);
      setError("date_of_birth", { message: "" });
      if (!minAgeStatus) {
        let fields = [
          {
            code: "guardian_name",
            type: "text",
            required: 1,

          },
          {
            code: "guardian_relation",
            type: "select",
            options: data?.guardianRelations,
            required: 1
          }
        ]
        const filteredContactField = contactField.filter(
          (field) =>
            !fields.some((newField) => newField.code === field.code)
        );
        const updatedContactField = [
          ...filteredContactField.slice(0, 3),
          ...fields,
          ...filteredContactField.slice(3),
        ];
        setcontactField(updatedContactField)
      } else {
        setcontactField(data?.contactInformation?.contactField)

      }
    }
  };
  // -------------------------- validation Fn's----------------------------------------------
  const minAgeValidation = (selectedDate, ageLimit) => {
    if (selectedDate) {
      const today = dayjs();
      const minAge = today.subtract(ageLimit, "year"); // Calculate the minimum Age
      return selectedDate.isBefore(minAge);
    } else {
      // Handle the case when selectedDate is null or undefined
      return false; // Or you can throw an error or handle it differently
    }
  };

  const maxAgeValidation = (selectedDate, ageLimit) => {
    if (selectedDate) {
      const today = dayjs();
      const maxAge = today.subtract(ageLimit, "year"); // Calculate the max Age
      return !selectedDate.isAfter(maxAge);
    } else {
      return false;
    }
  }

  const handleUsernameCheck = async (item) => {
    const { name, value } = item;
    checkUsernameField.mutate({ field: name, value: value });
  };

  const handleEmailCheck = async (item) => {
    const { name, value } = item;
    checkEmailField.mutate({ field: name, value: value });
  };

  const handleTransPassword = async (item) => {
    const { value } = item;
    setTransPass(value);
    setTransPassResposne({
      success: null,
      error: null,
    });
  };
  useEffect(() => {
    setcontactField(data?.contactInformation?.contactField)
    setcontactCustomField(data?.contactInformation?.customField)
  }, [data?.contactInformation])
  const handleCountry = (selectedCountry, statesData) => {
    if (selectedCountry) {
      setValue("country", selectedCountry);
      formValues.country = selectedCountry;
    }
    statesData?.map((value) => {
      if (parseInt(selectedCountry) === value.id) {
        setStates(value.States);
      }
    });
  };

  const handlePhoneNumber = (phone) => {
    setPhoneNumber(phone);
    setValue("mobile", phone);
    setError("mobile", { message: "" });
  };
  const isPhoneValid = (phone) => {
    // international phoneUtil validation is commented
    // return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));

    // Add minimum length validation
    return phone.length >= 7 && !isNaN(phone);
  };
  const isPhoneNumberValid = isPhoneValid(phoneNumber);

  const removeCustomFields = (payload, customField) => {
    customField.forEach(field => {
      const key = field.code;
      delete payload[key];
    });
    return payload;
  }

  useEffect(() => {
    if (position === undefined) {
      setValue("position", null);
    } else {
      setValue("position", String(position));
    }
  }, [position]);

  useEffect(() => {
    if (data?.regPack && data?.regPack.length === 1) {
      const { id, name, pairValue, price } = data.regPack[0];
      if (regAmount !== undefined) {
        const totalAmount = Number(price) + JSON.parse(regAmount);
        setValue("totalAmt", `${CurrencyConverter(totalAmount, conversionFactor)}`);
        setValue("totalAmount", totalAmount.toFixed(2));
      }
      setValue("product",
        { id: id, name: name, price: price },
        { shouldValidate: true }
      );
      setValue("pv", pairValue);
      setValue("regAmount", regAmount);
    }
  }, [data?.regPack])
  // console.log(errors, "errors-----");
  // console.log("====", formValues);
  useEffect(() => {
    setValue('totalAmount', data?.regData)
  }, [data?.regData])

  const handleChangeGuardianRelation = (value) => {
    setValue('guardian_relation', value?.value)
    setguardianRelation(value)
    if (value.label === "Other") {
      const field = [{
        code: 'relationship',
        type: 'text',
        required: true
      }]

      const removeDuplication = contactField.filter((items) => !field.some((field) => field.code === items.code))
      const newField = [
        ...removeDuplication.slice(0, 5),
        ...field,
        ...removeDuplication.slice(5)
      ]
      setcontactField(newField)
    } else {
      if (contactField.some((field) => field.code === "relationship")) {
        setcontactField(contactField.filter((obj) => obj.code != "relationship"))
      }
    }
  }
  const changeOccupation = (val, fieldId) => {
    setoccupation(val)
    setValue('occupation', val.value)
    handleCustomField(fieldId, val?.value)
    if (val?.label === "Other") {
      let newField = [
        {
          code: 'other_occupation_name',
          required: 1,
          type: 'text'
        }
      ]
      const filteredArray = contactCustomField.filter((field) => !newField.some((item) => item.code === field.code))
      const newFields = [
        ...filteredArray.slice(0, 1),
        ...newField,
        ...filteredArray.slice(1)
      ]
      setcontactCustomField(newFields)

    } else setcontactCustomField(data?.contactInformation?.customField)
  }

  const captchaChangeHandler = async (value) => {
    setcaptcha(value)
    const response = await verifyCaptcha.mutateAsync({ captcha: value })
    if (response.status) {
      if (response.data.captchaVerified) {
        setcaptchaVerified(true)
        setcaptchaErr('')
      }
    } else {
      setcaptchaVerified(false)
    }
  }
  return (
    <div className="main-content-regsiter">
      <div className="row justify-content-center pt-0 p-4" id="wizardRow">
        <div className="col-md-12 text-center">
          <div className="wizard-form py-4 my-2">
            <ul id="progressBar" className="progressbar px-lg-5 px-0">
              {steps?.map((step, index) => (
                <li
                  key={`step-${index + 1}`}
                  id={`progressList-${index + 1}`}
                  className={`d-inline-block w-20 position-relative text-center float-start progressbar-list ${((data?.regPack === null || data?.regPack.length === 0) && (data?.regData === 0 || data?.regData === null)) ? (activeStep - 1 >= step.step ? "active" : "") : (activeStep >= step.step ? "active" : "")}`}
                >
                  {t('step')}{step.step}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div id="animation">
        {activeStep === 0 && (data?.regData !== 0 || data?.regPack.length !== 0) && (
          <div className="row row_top justify-content-center" id="cardSection">
            <div className="col-lg-12 col-md-12">
              <div className="regsiter_step_1_view">
                <div className="row">
                  <div className="col-md-4">
                    <div className="regsiter_step_1_view_left_sec">
                      <div className="regsiter_step_1_view_left_sec_head">
                        {t("sponsor")}
                      </div>
                      <div className="regsiter_step_1_view_left_user_bx">
                        <div className="regsiter_step_1_view_left_user_bx_image">
                          <img
                            src={sponsorImage ?? "/images/user-profile.png"}
                            alt=""
                          />
                        </div>
                        <div className="regsiter_step_1_view_left_user_bx_txt">
                          <strong>{data?.sponsorData?.username}</strong>
                          <p>
                            {data?.sponsorData?.UserDetail?.fullName ? (
                              <>
                                {data?.sponsorData?.UserDetail?.fullName}
                                <br />
                                {data?.sponsorData?.email}
                              </>
                            ) : (
                              <Skeleton count={2} />
                            )}
                          </p>
                        </div>
                      </div>
                      {(moduleStatus?.mlm_plan === "Binary" ||
                        moduleStatus?.mlm_plan === "Matrix") && (
                          <>
                            {placement && (
                              <div className="placement_section_reg">
                                <strong>{t("placement")}</strong>
                                <span>{placement}</span>
                              </div>
                            )}
                            {moduleStatus?.mlm_plan === "Binary" && (
                              <>
                                <div className="regsiter_step_1_view_left_btn_row">
                                  <label
                                    className={`regsiter_step_1_view_left_btn ${formValues.position === "L" ? "active" : ""
                                      }`}
                                  >
                                    <input
                                      type="radio"
                                      name="position"
                                      value={"L"}
                                      disabled={position === "R"}
                                      {...register("position", {
                                        required: true,
                                      })}
                                    />
                                    {t("left")}
                                  </label>
                                  <label
                                    className={`regsiter_step_1_view_left_btn ${formValues.position === "R" ? "active" : ""
                                      }`}
                                  >
                                    <input
                                      type="radio"
                                      name="position"
                                      value={"R"}
                                      disabled={position === "L"}
                                      {...register("position", {
                                        required: true,
                                      })}
                                    />
                                    {t("right")}
                                  </label>
                                </div>
                                {errors["position"] &&
                                  formValues.position === null && (
                                    <span className="error-message-validator">
                                      {t("this_field_is_required")}
                                    </span>
                                  )}
                              </>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="right_product_selection_bx">
                      <div className="regsiter_step_1_view_left_sec_head">
                        {data?.registrationSteps
                          ? t(
                            `${data?.registrationSteps[activeStep - 1]?.label
                            }`
                          )
                          : t("pick_your_products")}
                      </div>
                      <div className="right_product_lsting_section">
                        <div className="row">
                          {((data?.regPack?.length === 0 || data?.regPack === null) && data?.regData !== 0) && (
                            <span>
                              <div className="col-md-4">
                                <div
                                  className={`right_product_box card active-card`}
                                >
                                  <div className="right_product_box_image">
                                    <img src={"/images/product1.jpg"} />
                                  </div>
                                  <div className="right_product_box__dtails">
                                    <div className="right_product_box__dsc">
                                      <br />
                                      <strong>
                                        {currency?.symbolLeft}{" "}
                                        {CurrencyConverter(
                                          data?.regData,
                                          conversionFactor
                                        )}
                                      </strong>
                                      <br />
                                      {data?.regPack?.length !== 0 ? (
                                        <>
                                          {"PV - "}
                                          {data?.regData}
                                        </>
                                      ) : (
                                        <br />
                                      )
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          )}
                          {data?.regPack?.map((product, index) => (
                            <div className="col-md-4" key={index}>
                              <div
                                className={`right_product_box card ${formValues.product?.id === product.id
                                  ? "active-card"
                                  : ""
                                  }`}
                                {...register("product", { required: true })}
                                onClick={() =>
                                  handleProductClick(
                                    product.id,
                                    product.name,
                                    product.pairValue,
                                    product.price
                                  )
                                }
                              >
                                <div className="right_product_box_image">
                                  <img
                                    src={
                                      product.image ?? "/images/product1.jpg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="right_product_box__dtails">
                                  <div className="right_product_box__head">
                                    {product.name}
                                  </div>
                                  <div className="right_product_box__dsc">
                                    <strong>
                                      {currency?.symbolLeft}{" "}
                                      {CurrencyConverter(
                                        product.price,
                                        conversionFactor
                                      )}
                                    </strong>
                                    <br />
                                    {"PV - "}
                                    {product.pairValue}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* skeleton */}
                          {data?.regPack === undefined && (
                            <div className="col-md-4">
                              <div className="right_product_box card">
                                <div className="right_product_box_image">
                                  <Skeleton width={125} height={125} />
                                </div>
                                <div className="right_product_box__dtails">
                                  <div className="right_product_box__head">
                                    <Skeleton width={125} />
                                    <Skeleton width={80} count={2} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {errors.product && (
                          <span className="error-message-validator">
                            {t("this_field_is_required")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                onClick={handleNext}
              >
                {t("next")}
              </button>
            </div>
          </div>
        )}
        {activeStep === 1 && (
          <div className="row row_top form-business">
            {data?.regData != 0 && data?.regPack === null &&
              <div className="col-lg-7">
                <div className="regsiter_step_1_view_left_sec_head">{t("sponsor")}</div>
                <div className="regsiter_step_1_view_left_user_bx_1">
                  <div className="regsiter_step_1_view_left_user_bx_image_1">
                    <img
                      src={sponsorImage ?? "/images/user-profile.png"}
                    />
                  </div>
                  <div className="regsiter_step_1_view_left_user_bx_txt">
                    <strong>{data?.sponsorData?.username}</strong>
                    <p>
                      {data?.sponsorData?.UserDetail?.fullName ? (
                        <>
                          {data?.sponsorData?.UserDetail?.fullName}
                          <br />
                          {data?.sponsorData?.email}
                        </>
                      ) : (
                        <Skeleton count={2} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            }
            {/* Placement without Product and RegFee */}
            {((data.regPack === null || data.regPack.length === 0) && (data.regData === 0 || data.regData === null)) && moduleStatus?.mlm_plan === "Binary" && (
              <div className="col-lg-5">
                <div className="placement_section_reg">
                  <strong>{t("placement")}</strong>
                  {placement && <span>{placement}</span>}
                </div>
                <div className="regsiter_second_step_bx reg_select_pos">
                  <div className="regsiter_step_1_view_left_btn_row mt-0">
                    <label
                      className={`regsiter_step_1_view_left_btn ${formValues.position === "L" ? "active" : ""
                        }`}
                    >
                      <input
                        type="radio"
                        name="position"
                        value={"L"}
                        disabled={position === "R"}
                        {...register("position", {
                          required: true,
                        })}
                      />
                      {t("left")}
                    </label>
                    <label
                      className={`regsiter_step_1_view_left_btn ${formValues.position === "R" ? "active" : ""
                        }`}
                    >
                      <input
                        type="radio"
                        name="position"
                        value={"R"}
                        disabled={position === "L"}
                        {...register("position", {
                          required: true,
                        })}
                      />
                      {t("right")}
                    </label>

                  </div>
                </div>
              </div>
            )}
            <div className="regsiter_step_1_view_left_sec_head">
              {data?.registrationSteps && (
                (data.regPack === null || data.regPack.length === 0) && (data.regData === 0 || data.regData === null)
                  ? t(data?.registrationSteps[activeStep - 2]?.label)
                  : (data.regPack === null || data.regPack.length === 0) && (data.regData !== 0 || data.regData !== null)
                    ? t(data?.registrationSteps[activeStep - 1]?.label)
                    : t(data?.registrationSteps[activeStep - 1]?.label)
              )}
            </div>
            <div className="regsiter_second_step_bx">
              <Alert variant="warning"><i class="fa-solid fa-triangle-exclamation fa-fade"></i>{" "}<span>{t('enter_verified_mail')}</span></Alert>
              <div className="row">
                {contactField?.map((item, index) => (
                  console.log("======contactInformationitem====================", item.required),
                  <div className="col-md-6" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {t(`${item.code}`)}
                        <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                      </label>
                      {item.type === "text" &&
                        item.code !== "state" &&
                        !item.options && (
                          <input
                            id={item.code}
                            name={item.code}
                            type="text"
                            className={`form-control ${errors[item.code] ? "error-field" : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required == 1 ? true : false,
                                message: t("this_field_is_required"),
                              },
                              // pattern: {
                              //   value: /^[A-Za-z0-9]+$/,
                              //   message: t("invalid_format"),
                              // },
                            })}
                            onBlur={async () => await trigger(item.code)}
                          />
                        )}
                      {item.type === "email" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="email"
                            className={`form-control ${(!checkEmailField.data?.data?.status &&
                              checkEmailField.data?.data?.field === "email" &&
                              checkEmailField.data?.data?.code === 1117) ||
                              errors[item.code]
                              ? "error-field"
                              : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required === 1 ? true : false,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                message: t("invalid_email_format"),
                              },
                            })}
                            onBlur={async () => await trigger(item.code)}
                            onChangeCapture={(e) => {
                              handleEmailCheck(e.target);
                            }}
                          />
                          {!checkEmailField.data?.data?.status &&
                            checkEmailField.data?.data?.field === "email" &&
                            checkEmailField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "date" && (
                        <DatePickerComponent
                          className={`date-picker ${errors[item.code] ? "error-field" : ""
                            }`}
                          date={selectedDob}
                          handleDateChange={(newDate) =>
                            handleDateChange(newDate, item)
                          }
                          isCalenderOpen={isCalenderOpen}
                          openCalender={openCalender}
                          closeCalender={closeCalender}
                        />
                      )}
                      {item.type === "number" && item.code === "mobile" && (
                        <PhoneInput
                          defaultCountry="us"
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                        />
                      )}
                      {item.type === "number" && !(item.code === "mobile") && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="number"
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required === 1 ? true : false,
                              message: t("this_field_is_required"),
                            },
                            minLength: {
                              value: 5,
                              message: t("min_length_of_5"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                        />
                      )}

                      {item.type === "text" && item.code === "gender" && (
                        <Select
                          id={item.code}
                          name={item?.code}
                          className={`dropdown-common ${errors[item.code] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required === 1 ? true : false,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                          options={genderOptions(item?.options)}
                          onChange={genderChange}
                          value={selectedGender}
                          isSearchable={false}
                        />
                      )}
                      {item.type === "text" && item.code === "country" && (
                        <Select
                          isSearchable={false}
                          id={item.code}
                          name={item?.code}
                          className={` ${errors[item.code] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required === 1 ? true : false,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                          value={countryList(item?.options).find(
                            (item) => item.value === selectedCountry
                          )}
                          options={countryList(item?.options)}
                          onChange={changeCountry}
                        />
                      )}
                      {item.type === "text" && item.code === "state" && (
                        <Select
                          isSearchable={false}
                          id={item.code}
                          name={item?.code}
                          className={` ${errors[item.code] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required === 1 ? true : false,
                              message: t("this_field_is_required"),
                            },
                          })}
                          options={stateList(item?.options)}
                          onBlur={async () => await trigger(item.code)}
                          value={stateList(item?.options).find(
                            (state) => state?.value === selectedState
                          )}
                          onChange={changeState}
                        />
                      )}
                      {
                        item.type === "select" && item.code === "guardian_relation" && (
                          <Select
                            id={item.code}
                            name={item?.code}
                            className={`dropdown-common ${errors[item.code] ? "error-field" : ""
                              }`}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                            })}
                            onBlur={async () => await trigger(item.code)}
                            options={item?.options}
                            onChange={handleChangeGuardianRelation}
                            value={guardianRelation}
                            isSearchable={false}
                          />
                        )
                      }
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                {contactCustomField?.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {t(item.code)}{" "}
                        <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                      </label>
                      {item.type === "text" && !item.options && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="text"
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required == 1 ? true : false,
                              message: t("this_field_is_required"),
                            },
                            // pattern: {
                            //   value: /^[A-Za-z0-9]+$/,
                            //   message: t("invalid_format"),
                            // },
                          })}
                          onChangeCapture={(e) => {
                            const inputValue = e.target.value;

                            if (item.code !== "other_occupation_name") {
                              // Handle the field normally
                              handleCustomField(item.id, inputValue);
                            } else {

                            }
                          }}
                        />
                      )}
                      {item.type === "text" && item.code === "occupation" && (
                        <>
                          <Select
                            isSearchable={false}
                            id={item.code}
                            name={item?.code}
                            className={` ${errors[item.code] ? "error-field" : ""
                              }`}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                            })}
                            onBlur={async () => await trigger(item.code)}
                            value={occupation}
                            options={item?.options}
                            onChange={(value) => changeOccupation(value, item.id)}
                          />
                        </>


                      )}
                      {item.type === "email" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="email"
                            className={`form-control ${(!checkEmailField.data?.data?.status &&
                              checkEmailField.data?.data?.field === "email" &&
                              checkEmailField.data?.data?.code === 1117) ||
                              errors[item.code]
                              ? "error-field"
                              : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                message: t("invalid_email_format"),
                              },
                            })}
                            onChangeCapture={async (e) => {
                              const inputValue = e.target.value; // Get the current value of the input field
                              handleEmailCheck(e.target);
                              handleCustomField(item.id, inputValue);
                            }}
                          />
                          {!checkEmailField.data?.data?.status &&
                            checkEmailField.data?.data?.field === "email" &&
                            checkEmailField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "date" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="date"
                            className={`form-control ${errors[item.code] ? "error-field" : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              validate: (value) => validateAge(value, t),
                            })}
                            onChange={async (e) => {
                              const inputValue = e.target.value; // Get the current value of the input field
                              handleCustomField(item.id, inputValue);
                              await trigger(item.code);
                            }}
                          />
                          {errors.date_of_birth && (
                            <span className="error-message-validator">
                              {errors.date_of_birth.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.type === "textarea" && (
                        <textarea
                          id={item.code}
                          name={item.code}
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        />
                      )}
                      {item.type === "number" && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="number"
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            minLength: {
                              value: 5,
                              message: t("min_length_of_5"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        />
                      )}
                      {item.type === "text" && item.code === "gender" && (
                        <select
                          id={item.code}
                          name={item.code}
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          {item?.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                              {option.title}
                            </option>
                          ))}
                        </select>
                      )}
                      {item.type === "text" && item.code === "country" && (
                        <select
                          id={item.code}
                          name={item.code}
                          className={`form-control ${errors[item.name] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCountry(e.target.value, item.options);
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          <option>{t("select_the_country")}</option>
                          {item?.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {item.type === "text" && item.code === "state" && (
                        <select
                          id={item.code}
                          name={item.name}
                          className={`form-control ${errors[item.name] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          {states?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  {/* <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button> */}
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div className="row row_top justify-content-center form-business">
            <div className="regsiter_step_1_view_left_sec_head">
              {data?.registrationSteps && (
                data.regPack === null && data.regData === 0
                  ? t(data?.registrationSteps[activeStep - 2]?.label)
                  : data.regPack === null && data.regData !== 0
                    ? t(data?.registrationSteps[activeStep - 1]?.label)
                    : t(data?.registrationSteps[activeStep - 1]?.label)
              )}
            </div>
            <div className="regsiter_second_step_bx">
              <div className="row">
                {data?.loginInformation.map((item, index) => (
                  <div className="col-md-12" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {t(item.code)} <span className="text-danger">٭</span>
                      </label>
                      {item.type === "text" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type={item.type}
                            className={`form-control ${(!checkUsernameField.data?.data?.status &&
                              checkUsernameField.data?.data?.field ===
                              "username" &&
                              checkUsernameField.data?.data?.code === 1117) ||
                              errors[item.code]
                              ? "error-field"
                              : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(
                              item.code,
                              loginUsernameValidator(item, t)
                            )}
                            onChangeCapture={async (e) => {
                              await handleUsernameCheck(e.target);
                            }}
                            onBlur={async () => await trigger(item.code)}
                          />
                          {!checkUsernameField.data?.data?.status &&
                            checkUsernameField.data?.data?.field ===
                            "username" &&
                            checkUsernameField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("username_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "password" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type={showPassword ? "text" : "password"}
                            style={{ marginBottom: "8px" }}
                            className={`form-control ${errors[item.code] ? "error-field" : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(
                              item.code,
                              loginPasswordValidator(item, t)
                            )}
                            onBlur={async () => await trigger(item.code)}
                          />
                          <InputAdornment
                            position="end"
                            className="replica-eye"
                          >
                            <IconButton
                              onClick={handleShowPassword}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                          <PasswordChecklist
                            rules={passwordRules(item.validation)}
                            minLength={item.validation.minLength}
                            value={watch("password", "")}
                            className="mb-3"
                          />
                          <label htmlFor={"confirmPassword"}>
                            {t("confirmPassword")}{" "}
                            <span className="text-danger">٭</span>
                          </label>
                          <input
                            id={"confirmPassword"}
                            name={"confirmPassword"}
                            type={"password"}
                            style={{ marginBottom: "8px" }}
                            className={`form-control ${errors["confirmPassword"] ? "error-field" : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register("confirmPassword", {
                              required: {
                                message: t("this_field_is_required"),
                              },
                              validate: (value) =>
                                value === getValues("password") ||
                                t("passwordNotMatch"),
                            })}
                            onBlur={async () =>
                              await trigger("confirmPassword")
                            }
                          />
                          {errors["confirmPassword"] &&
                            item.code !== "username" && (
                              <span className="error-message-validator">
                                {errors["confirmPassword"].message}
                              </span>
                            )}
                        </>
                      )}
                      {
                        item.type === "select" && (
                          <Controller
                            name={item.code}
                            control={control}
                            rules={{ required: { value: true, message: t('this_field_is_required') }, }}
                            render={({ field }) => <Select
                              {...field}
                              // className={`dropdown-common ${errors[item.code] ? "error-field" : ""
                              //   }`}
                              options={item.options}
                              onChange={(selectedOption) => field.onChange(selectedOption)}

                            />}
                          />
                        )
                      }
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {t(errors[item.code].message)}
                        </span>
                      )}

                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  <div className="regsiter_form_box_tandc">
                    <label className="d-flex" htmlFor="box">
                      <input
                        name={"termsAndCondition"}
                        type="checkbox"
                        id="box"
                        style={{ marginRight: "5px" }}
                        {...register("termsAndCondition", {
                          required: true,
                        })}
                      />
                    </label>
                    <a
                      data-bs-toggle="modal"
                      className="pop_terms_btn"
                      onClick={handleToggleRegisterModal}
                    >
                      {t("iAcceptTermsAndConditions")}
                      <span className="text-danger"> ٭ </span>
                    </a>
                    {errors["termsAndCondition"] &&
                      formValues["termsAndCondition"] === false && (
                        <span className="error-message-validator">
                          *{t("required")}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 3 && (
          <div className="row row_top justify-content-center form-business">
            <div className="regsiter_second_step_bx">
              <div className="regsiter_overview_box">
                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t("product_and_sponsor")}</strong>
                </div>
                <div className="row">
                  {!data?.regData && data.regPack !== null && (
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor="product">{t("product")}</label>
                      <strong id="product">{formValues?.product?.name}</strong>
                    </div>
                  )}
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="sponsor">{t("sponsor")}</label>
                    <strong id="sponsor">{data?.sponsorData?.username}</strong>
                  </div>
                  {/* {data.regPack!== null &&
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor="pv">{t("pv")}</label>
                      <strong id="pv">{formValues?.pv}</strong>
                    </div>
                  } */}
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="price">{t("price")}</label>
                    <strong id="price">{`${currency?.symbolLeft
                      } ${CurrencyConverter(
                        formValues?.product?.price
                          ? formValues?.product?.price
                          : data?.regData,
                        conversionFactor
                      )}`}</strong>
                  </div>
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="total">{t("totalAmount")}</label>
                    <strong id="total">{`${currency?.symbolLeft
                      } ${CurrencyConverter(
                        formValues?.totalAmount
                          ? formValues?.totalAmount
                          : data?.regData,
                        conversionFactor
                      )}`}</strong>
                  </div>
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t(`${data?.registrationSteps[1]?.label}`)}</strong>
                </div>

                <div className="row">
                  {Object.entries(formValues)?.map(
                    ([key, value]) => {
                      if (![
                        "product",
                        "PV",
                        "regAmount",
                        "termsAndCondition",
                        "totalAmt",
                        "paymentType",
                        "customFields",
                        "bankReceipt",
                        "referralId",
                        "totalAmount",
                        "epins",
                        "regAmount",
                        "transactionPassword",
                        "totalEpinAmt",
                        "username",
                        "password",
                        "pv",
                        "confirmPassword",
                        "noProduct",
                        "position", "role"
                      ].includes(key)) {
                        if ((key === "country" || key === "state" || key === "occupation" || key === "guardian_relation") && value !== undefined && value !== null) {
                          return (
                            <div className="col-md-4 mb-3 regsiter_overview_col" key={key}>
                              <label htmlFor={`input-${key}`}>{t(key)}</label>
                              <strong id={`input-${key}`}>
                                {key === "country" && countries.data.find(country => country.value === value)?.label}
                                {key === "state" && countryStates.data.find(state => state.value === value)?.label}
                                {key === "occupation" && data?.contactInformation?.customField.find(field => field.code === "occupation")?.options.find((obj) => obj.value == formValues.occupation).label}
                                {key === "guardian_relation" && data?.guardianRelations?.find(obj => obj.value === formValues?.guardian_relation)?.label}
                              </strong>
                            </div>
                          );
                        } else if (key !== "country" && key !== "state" && key !== "occupation" && key !== "guardian_relation") {
                          return (
                            <div className="col-md-4 mb-3 regsiter_overview_col" key={key}>
                              <label htmlFor={`input-${key}`}>{t(key)}</label>
                              <strong id={`input-${key}`}>
                                {value}
                              </strong>
                            </div>
                          );
                        }
                      }
                      return null; // Return null for keys in the exclusion list or when country/state value is null/undefined
                    }
                  )}
                  {formValues?.customField?.map(([key, value]) => (
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor={`input-${key}`}>{t(key)}</label>
                      <strong id={`input-${key}`}>{value}</strong>
                    </div>
                  ))}
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t(`${data?.registrationSteps[2]?.label}`)}</strong>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="username">{t("username")}</label>
                    <strong id="username">{formValues.username}</strong>
                  </div>
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="password">{t("password")}</label>
                    <strong id="password">********</strong>
                  </div>
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="password">{t("role")}</label>
                    <strong id="password">{formValues?.role?.value}</strong>
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  {(data.regData === 0 && data.regPack.length === 0) ? (
                    <button
                      type="submit"
                      className="btn text-white float-end submit-button rounded-3 bg-color-info"
                      disabled={submitButtonActive}
                      onClick={handleSubmit}
                    >
                      {t("finish")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                      onClick={handleNext}
                    >
                      {t("next")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 4 && (
          <div className="row row_top justify-content-center form-business">
            <div className="col-lg-12 col-md-12" id="payment">
              <div className="payment_section_tab">
                <div className="regsiter_step_1_view_left_sec_head">
                  {data?.registrationSteps && (
                    data.regPack === null && data.regData === 0
                      ? t(data?.registrationSteps[activeStep - 2]?.label)
                      : data.regPack === null && data.regData !== 0
                        ? t(data?.registrationSteps[activeStep - 1]?.label)
                        : t(data?.registrationSteps[activeStep - 1]?.label)
                  )}
                  <br />
                  <strong>
                    {t("totalAmount")}: {currency.symbolLeft}{" "}
                    {CurrencyConverter(
                      formValues.totalAmount
                        ? formValues.totalAmount
                        : data?.regData,
                      conversionFactor
                    ) ?? regAmount}
                  </strong>
                </div>

                <div className="row">
                  <div className="col-md-3 pe-0">
                    <div
                      className="nav nav-tabs payment_tab_reg"
                      id="nav-tab"
                      role="tablist"
                    >
                      {data?.paymentGateways?.map((tab) => (
                        <Fragment key={tab.id}>
                          <button
                            key={tab.id}
                            className={`nav-link ${activeTab === tab.id ? "active" : ""
                              }`}
                            id={`nav-${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#nav-${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={`nav-${tab.id}`}
                            aria-selected={formValues.paymentType === tab.id}
                            {...register("paymentType", { required: true })}
                            onClick={() =>
                              handlePaymentTabClick(tab.id, tab.title)
                            }
                          >
                            <i className={tab?.icon}></i> {t(tab.title)}
                          </button>
                        </Fragment>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-9 ps-0">
                    <div
                      className="tab-content p-3 border mb-5 payment__tab_cnt"
                      id="nav-tabContent"
                    >
                      {formValues.paymentType === undefined && (
                        <p>
                          <strong>{t("preferredPaymentPlaceholder")}</strong>
                        </p>
                      )}
                      {data?.paymentGateways?.map((tab) => (
                        <div
                          key={tab.id}
                          className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""
                            }`}
                          id={`nav-${tab.id}`}
                          role="tabpanel"
                          aria-labelledby={`nav-${tab.id}-tab`}
                        >
                          {tab.title === "e-pin" && (
                            <>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>{t("epin")}</label>
                                    <MultiSelect
                                      disableSearch
                                      options={epinList}
                                      value={epinValues}
                                      onChange={handleEpinChange}
                                      labelledBy="Select"
                                      hasSelectAll={false}
                                      disabled={formValues.epinBalance <= 0}
                                      closeOnChangedValue={
                                        formValues.epinBalance <= 0
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="elawwet_blance_sec">
                                  {epinValues.map((epinItem, index) => (
                                    <div
                                      className="elawwet_blance_row"
                                      key={index}
                                    >
                                      <span>{epinItem.value}</span>
                                      <strong>
                                        {t("amount")}: {currency?.symbolLeft}
                                        {CurrencyConverter(
                                          epinItem.amount,
                                          conversionFactor
                                        )}
                                      </strong>
                                      <a
                                        href="#/"
                                        className="epin_ball_clear"
                                        onClick={() => removeItemByIndex(index)} // Call the remove function with the index
                                      >
                                        <i className="fa fa-close"></i>
                                      </a>
                                    </div>
                                  ))}
                                </div>
                                <div className="valid_epi_pin_box">
                                  {t("valid_epin_data")}
                                </div>
                                <div className="total_epin_detail">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>{t("total_epin_amount")}</td>
                                        <td className="text-end">
                                          <strong>
                                            {currency?.symbolLeft}
                                            {CurrencyConverter(
                                              formValues?.totalEpinAmount,
                                              conversionFactor
                                            ) ?? 0}
                                          </strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t("totalAmount")}</td>
                                        <td className="text-end">
                                          <strong>
                                            {currency?.symbolLeft}
                                            {CurrencyConverter(
                                              formValues?.totalAmount,
                                              conversionFactor
                                            )}
                                          </strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t("balance")}</td>
                                        <td className="text-end">
                                          <strong>
                                            {currency?.symbolLeft}
                                            {formValues?.epinBalance !== undefined
                                              ? CurrencyConverter(
                                                formValues?.epinBalance,
                                                conversionFactor
                                              )
                                              : CurrencyConverter(
                                                formValues?.totalAmount,
                                                conversionFactor
                                              )}
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className='google-captcha mt-3 d-flex flex-column  justify-content-center align-items-end'>
                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                  onChange={captchaChangeHandler}
                                />
                                <span className='text-danger text-center' style={{ fontSize: '15px' }}>{captchaErr}</span>

                              </div>
                            </>

                          )}
                          {tab.title === "e-wallet" && (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-2">
                                  <p>{`${t("ewalletBalance")} : ${currency.symbolLeft
                                    } ${CurrencyConverter(
                                      userBalance?.data?.balanceAmount,
                                      conversionFactor
                                    )}`}</p>
                                  <br />
                                  <label htmlFor="transactionPassword">
                                    {t("transaction_password")}
                                  </label>
                                  <input
                                    type="password"
                                    placeholder=""
                                    className="form-control"
                                    name="transPassword"
                                    onChange={(e) =>
                                      handleTransPassword(e.target)
                                    }
                                  />
                                  {transPassResposne?.success && (
                                    <div style={{ color: "green" }}>
                                      {t(transPassResposne?.success)}
                                    </div>
                                  )}
                                  {transPassResposne?.error && (
                                    <div style={{ color: "red" }}>
                                      {t(transPassResposne?.error)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <button
                                  href="#"
                                  className="btn btn-sm btn-primary mt-3"
                                  onClick={() => setTransPassCheck(true)}
                                  disabled={!transPass}
                                >
                                  {t("apply")}
                                </button>
                              </div>
                              <span className="error-message-validator">
                                {transPassCheckData.data?.message}
                              </span>
                            </div>
                          )}
                          {tab.title === "free-joining" && (
                            <p>
                              <strong>{t("freeJoinPlaceHolder", { type: "registration" })}</strong>
                            </p>
                          )}
                          {tab.title === "pay-tabs" && (
                            <strong>{t("freeJoinPlaceHolder", { type: "registration" })}</strong>
                          )}
                          {tab.title === "bank-transfer" && (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-2">
                                  <label htmlFor="fileUpload">
                                    {t("file_upload")}
                                  </label>
                                  <input
                                    id="fileUpload"
                                    type="file"
                                    placeholder=""
                                    className="form-control"
                                    name="fileUpload"
                                    onChange={handleFileChange}
                                  />
                                  {fileResponse?.success &&
                                    formValues?.bankReceipt !== undefined && (
                                      <div style={{ color: "green" }}>
                                        {t(fileResponse?.success)}
                                      </div>
                                    )}
                                  {fileResponse?.error && (
                                    <div style={{ color: "red" }}>
                                      {t(fileResponse?.error)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <button
                                  href="#"
                                  className="btn btn-sm btn-primary mt-3"
                                  onClick={handleUpload}
                                  disabled={
                                    Upload.status === "loading" ||
                                    formValues?.bankReceipt ||
                                    document.getElementById("fileUpload")
                                      ?.value === "" ||
                                    file === null
                                  }
                                >
                                  {Upload.status === "loading"
                                    ? "Uploading..."
                                    : t("upload")}
                                </button>
                              </div>
                              {Upload?.data?.status === true &&
                                formValues?.bankReceipt !== undefined && (
                                  <>
                                    <img
                                      src={`${BASE_URL}${Upload?.data?.data?.file?.path}`}
                                      alt="receipt"
                                      style={{
                                        width: "160px",
                                        height: "160px",
                                        padding: "5px",
                                      }}
                                    />
                                    <button
                                      className="recipt_checkout_address_btn"
                                      onClick={handleDeleteBankReciept}
                                    >
                                      <i className="fa fa-xmark"></i>
                                    </button>
                                    <div style={{ color: "green" }}>
                                      {Upload?.data?.message}
                                    </div>
                                  </>
                                )}
                              {Upload?.data?.status === false && (
                                <span className="error-message-validator">
                                  {Upload?.data?.message}
                                </span>
                              )}
                            </div>
                          )}
                          {tab.title === "stripe" && clientSecret !== '' && (
                            <p>
                              <Stripe clientSecret={clientSecret} handleSubmit={handleSubmit} publicKey={stripePublicKey} />
                            </p>
                          )}
                          {tab.title === "paypal" && (
                            <MyPayPalOrderButton
                              currency={currency?.code}
                              price={formValues.totalAmount}
                              handleSubmit={handleSubmit}
                              paymentId={tab.id}
                            />
                            // <div>
                            //   <p>
                            //     <strong>Addon Coming Soon</strong>
                            //   </p>
                            // </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {errors.paymentType && (
                  <span className="error-message-validator">*Required</span>
                )}
              </div>
              <button
                type="button"
                className="btn btn-dark text-white float-start back rounded-3"
                disabled={registerMutation.isLoading ? true : false}
                onClick={handleBack}
              >
                {t("back")}
              </button>
              {activeTab !== 5 &&
                <button
                  type="submit"
                  className="btn text-white float-end submit-button rounded-3 bg-color-info"
                  disabled={submitButtonActive}
                  onClick={handleSubmit}
                >
                  {t("finish")}
                </button>
              }
            </div>
          </div>
        )}
      </div>
      {registerMutation.status === "loading" && <div className="reg-submit-loader-overlay">
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <path fill="none" stroke="#7b33c2" stroke-width="8" stroke-dasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" stroke-linecap="round" style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}>
            <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
          </path></svg>
      </div>}
    </div>
  );
};

export default RegisterForm;